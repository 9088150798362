export function formatPrice(
  price: number | string = 0,
  showCurrency = true
): string {
  const priceArray = `${price}`.split(".");
  return (
    (showCurrency ? "$" : "") +
    priceArray[0]
      .toString()
      .match(/.{1,3}(?=(.{3})*$)/g)
      ?.join(",") +
    (priceArray[1] ? "." + priceArray[1] : "")
  );
}

export const formatStripePrice = (value = 0, showCurrency = true): string => {
  let string = String(value);
  string = string.slice(0, -2) + "." + string.slice(-2);
  const lastTwo = string.substr(string.length - 2);
  const firstCharacter = string.substr(0, 1);
  if (firstCharacter === ".") {
    string = "0" + string;
  }
  if (lastTwo === "00") {
    return formatPrice(string.slice(0, -3), showCurrency);
  }
  return formatPrice(string, showCurrency);
};

export const formatPriceToNumber = (price: string): number => {
  return parseFloat(price.replace(/,/g, '').replace(/\$/g, ''));
}


export const formatPriceTrim = (price: number | string = 0,) : string => {
  const trimText = price ? price.toString()
                        .replace(/[^\d.]/g, '')
                        : undefined;
  if(!trimText || trimText === '$') {
    return '$0';
  }
  return formatPrice(trimText);
};

export const formatPorcentageTrim = (porcentage: number | string = 0, max ?: number) : string => {
  let integerValue = porcentage;
  let decimalValue = '';
  if(typeof porcentage === 'string' && porcentage.includes('.')) {
    const porcentageParts = porcentage.split('.');
    integerValue = porcentageParts[0];
    decimalValue = '.' + porcentageParts[1];
  }
  const trimPorcentage = formatPriceTrim(integerValue).replace(/\$/g, '');
  if(!trimPorcentage) {
    return '0.0';
  }
  if(max && trimPorcentage.replace(/,/g, '') as unknown as number >= max) {
    return max.toString();
  }
  return integerValue + decimalValue;
};

export const updatePriceInput = (e : Event, form: Record<string, unknown>, priceField: string) => {
  e.preventDefault();
  const target = e.target as HTMLInputElement;
  const value = formatPriceTrim(target.value);
  form[priceField] = formatPriceToNumber(value);
}
