export const debounce = (fn: any, delay = 400, immediate = false) => {
  let timeout: number | undefined;
  return (...args: any[]) => {
    if (immediate && !timeout) fn(...args);
    clearTimeout(timeout);

    timeout = setTimeout(() => {
      fn(...args);
    }, delay);
  };
};
