import { LotStatus } from "@/enums/LotStatus";

export const notEditableLotStatuses = [
  LotStatus.EOI,
  LotStatus.CONTRACT_EXECUTED,
  LotStatus.UNCONDITIONAL,
];

export function canEditLot(status: LotStatus) {
  return !notEditableLotStatuses.includes(status);
}
