import { useToast } from "vue-toastification";

export const MaxFileSize = 26214400;
const toast = useToast();
export const validDocumentsTypes = [
  "image/png",
  "image/jpeg",
  "image/heic",
  "image/heif",
  ".heic",
  "application/pdf",
];
export const validImagesTypes = [
  "image/png",
  "image/jpeg",
  "image/heic",
  "image/heif",
  ".heic",
];

export function isValidDocumentType(file: File, showErrorToast = true) {
  if (
    !validDocumentsTypes.includes(file.type) &&
    !file.name.toLowerCase().endsWith(".heic")
  ) {
    if (showErrorToast) {
      toast.error("Only png, jpg, jpeg, heic, pdf files allowed");
    }
    return false;
  }
  return true;
}
export function isValidImageType(file: File, showErrorToast = true) {
  if (
    !validImagesTypes.includes(file.type) &&
    !file.name.toLowerCase().endsWith(".heic")
  ) {
    if (showErrorToast) {
      toast.error("Only png, jpg, jpeg, heic files allowed");
    }
    return false;
  }
  return true;
}
export function isPngType(file: File, showErrorToast = true) {
  if (file.type !== "image/png") {
    if (showErrorToast) {
      toast.error("Only png files allowed");
    }
    return false;
  }
  return true;
}
export function isXlsxType(file: File, showErrorToast = true) {
  if (
    file.type !==
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
  ) {
    if (showErrorToast) {
      toast.error("Only xlsx files allowed");
    }
    return false;
  }
  return true;
}
export function isValidFileSize(file: File, showErrorToast = true) {
  if (file.size > MaxFileSize) {
    if (showErrorToast) {
      toast.error(
        `File size should not exceed ${Math.floor(
          MaxFileSize / 1024 / 1024
        )} MB`
      );
    }
    return false;
  }
  return true;
}
