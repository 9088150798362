import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "checkbox-wr" }
const _hoisted_2 = ["value", "checked", "disabled"]
const _hoisted_3 = {
  key: 0,
  class: "label"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("label", {
      class: _normalizeClass({ disabled: $props.disabled })
    }, [
      _createElementVNode("input", {
        type: "checkbox",
        value: $props.modelValue,
        checked: $props.modelValue,
        onChange: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:modelValue', !$props.modelValue))),
        disabled: $props.disabled
      }, null, 40, _hoisted_2),
      _createElementVNode("div", {
        class: _normalizeClass(["box", { partial: $props.checkedPartial }])
      }, null, 2),
      ($props.label)
        ? (_openBlock(), _createElementBlock("p", _hoisted_3, _toDisplayString($props.label), 1))
        : _createCommentVNode("", true)
    ], 2)
  ]))
}