const plural = (number: number, word: string, withNumber = true): string => {
  if (word.toLocaleLowerCase() === "this") {
    const firstChar = word[0];
    return number > 1 ? `${firstChar}hese` : `${firstChar}his`;
  }
  if (!number) {
    return `0 ${word}s`;
  } else return `${withNumber ? number : ""} ${word}${number < 2 ? "" : "s"}`;
};

export default plural;
